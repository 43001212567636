
.animate-scroll  {
    animation: webscrollAnimation 150s linear infinite;
}


@keyframes webscrollAnimation {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

