@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pretendard';
  src:  url('./assets/fonts/Pretendard-Regular.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'Pretendard';
  src:  url('./assets/fonts/Pretendard-Medium.otf') format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'Pretendard';
  src:  url('./assets/fonts/Pretendard-SemiBold.otf') format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: 'Pretendard';
  src:  url('./assets/fonts/Pretendard-ExtraBold.otf') format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'Pretendard';
  src:  url('./assets/fonts/Pretendard-Bold.otf') format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'Montserrat';
  src:  url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Montserrat';
  src:  url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Montserrat';
  src:  url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Montserrat';
  src:  url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Montserrat';
  src:  url('./assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

body {
  margin: 0;
  font-family: Montserrat, Pretendard,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  pointer-events: auto;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroll__container{
  position: fixed;
  right: 5%;
  bottom: 5%;
  z-index: 1;
}